import { useState, ChangeEvent, useEffect, ReactNode } from 'react'
import { Input, useFocusInput } from 'simple-core-ui'
import cn from 'classnames'
import s from './CharLimitInput.scss'

interface Props {
  value: string
  maxLength?: number
  onChangeCb: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: () => void
  onFocus?: () => void
  placeholder?: string
  hasError?: boolean
  cssClass?: string
  isDisabled?: boolean
  focused?: boolean
  dynamicCharCalculation?: boolean
  sectionStyles?: React.CSSProperties
  maxCharMsgClassName?: string
  type?: string
  autoGrowing?: boolean
  validation?: (isValid: boolean) => void
  hideInfoText?: boolean
  customErrorMessage?: string
  customErrorStyle?: React.CSSProperties
  refObject?:
    | React.MutableRefObject<HTMLTextAreaElement | null>
    | React.MutableRefObject<HTMLInputElement | null>
  footer?: ReactNode
  // rest of props
  [x: string]: unknown
  rows?: number
}

const CharLimitInput = ({
  value,
  maxLength = 250,
  onChangeCb,
  placeholder,
  hasError,
  cssClass,
  isDisabled,
  focused = false,
  dynamicCharCalculation = false,
  sectionStyles,
  maxCharMsgClassName,
  validation,
  onBlur,
  onFocus,
  autoGrowing,
  refObject,
  hideInfoText = false,
  customErrorMessage,
  customErrorStyle,
  footer = null,
  ...props
}: Props) => {
  useFocusInput(focused, refObject)
  const [hasLengthError, setHasLengthError] = useState(false)
  const [hasFocus, setFocus] = useState(false)

  const maxCharMsg = dynamicCharCalculation
    ? `${value?.length ?? 0}/${maxLength}`
    : `Max character limit: ${maxLength}`

  useEffect(() => {
    if (validation) {
      validation(hasLengthError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasLengthError])

  useEffect(() => {
    setHasLengthError(value.length > maxLength)
  }, [maxLength, value])

  return (
    <section style={{ ...sectionStyles, position: 'relative' }}>
      <Input
        {...props}
        autoGrowing={autoGrowing}
        type={props?.type || 'text'}
        onChangeCb={onChangeCb}
        placeholder={placeholder}
        className={s.nameInput}
        forwardedRef={refObject}
        text={value}
        onBlurCb={() => {
          setTimeout(() => {
            setFocus(false)
            onBlur?.()
          }, 100)
        }}
        onFocusCb={() => {
          setFocus(true)
          onFocus?.()
        }}
        cssClass={cn({
          error: hasLengthError || hasError,
          [cssClass as string]: cssClass
        })}
        isDisabled={isDisabled}
      />
      {footer ? footer : null}
      {!hideInfoText && (
        <div
          className={cn(
            s.rightInfo,
            {
              error: hasLengthError,
              [s.hidden]: value && !hasFocus && !hasLengthError && !hasError
            },
            maxCharMsgClassName
          )}
        >
          {maxCharMsg}
        </div>
      )}
      {customErrorMessage && (hasLengthError || hasError) && (
        <div className={cn(!hideInfoText && s.leftInfo, 'error')} style={{ ...customErrorStyle }}>
          {customErrorMessage}
        </div>
      )}
    </section>
  )
}

export default CharLimitInput
